import { useWire } from "@core/wire";
import {
  getApiTarget,
  getGitCommitRef,
  getPullRequestId,
  getVercelBranch,
  getVercelCommitSHA,
  getVercelEnv,
  getVercelURL,
} from "@repo/env";

export default function handler() {
  const wire = useWire();
  return (
    <div class="text-white">
      <h2>Environment</h2>
      <p>Environment component</p>
      <ul>
        <li>VITE_VERCEL_ENV: {getVercelEnv()}</li>
        <li>VITE_VERCEL_URL: {getVercelURL()}</li>
        <li>VITE_VERCEL_BRANCH_URL: {getVercelBranch()}</li>
        <li>VITE_VERCEL_GIT_COMMIT_REF: {getGitCommitRef()}</li>
        <li>VITE_VERCEL_GIT_COMMIT_SHA: {getVercelCommitSHA()}</li>
        <li>VITE_VERCEL_GIT_PULL_REQUEST_ID: {getPullRequestId()}</li>
        <li>STORYTELL_API_TARGET: {getApiTarget()}</li>
      </ul>
      <p>Serialized Values</p>
      <pre>
        <code>{JSON.stringify(wire.services.environment.settings.values, null, 2)}</code>
      </pre>
    </div>
  );
}
